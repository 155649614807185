<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.bannerUpdate')"
            :title="$t('messages.bannerUpdate')"
            @submitPressed="submitPressed"
        />
        <banner-form v-if="banner" :bannerObject="banner" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import BannerForm from './components/BannerForm.vue'

export default {
    components: {
        TitleBar,
        BannerForm,
    },
    data() {
        return {
            banner: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Banners.getResource({id}).then((response) => {
                this.banner = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
